import { getClientFields } from './analytics';

export function gtag(...args: any[]) {
  window?.gtag?.(...args);
}

export function dataLayer(...args: any[]) {
  window?.dataLayer?.push(...args);
}

const injectGTMNoScript = (d: Document, i: string) => {
  const ns = d.createElement('noscript');
  const f = d.createElement('iframe');

  f.src = `//www.googletagmanager.com/ns.html?id=${i}`
  f.height = '0';
  f.width = '0';
  f.style.display = 'none';
  f.style.visibility = 'hidden';

  ns.append(f)

  d.body.insertAdjacentElement('afterbegin', ns);
}

const injectGTMScript = (w: any, d: Document, s: 'script', i: string) => {
  const f = d.getElementById('gtag-gtm')!;
  const j = d.createElement(s);
  j.async = true;
  j.src = `//www.googletagmanager.com/gtm.js?id=${i}`;
  f.after(j);
};

const injectGtagScript = (w: any, d: Document, s: 'script') => {
  const f = d.getElementById('gtag-gtm')!;
  const j = d.createElement(s);
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtag/js';
  f.after(j);
};

export async function injectAnalytics(params: {
  tracking_google_analytics_id: string | null,
  tracking_org_google_analytics_id: string | null,
  tracking_org_gtm_container: string | null,
}) {
  // needs to be either gtm or gtag
  if (params.tracking_org_gtm_container !== null) {
    dataLayer({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    injectGTMScript(window, document, 'script', params.tracking_org_gtm_container);
    injectGTMNoScript(document, params.tracking_org_gtm_container);
  } else {
    injectGtagScript(window, document, 'script');
  }

  if (params.tracking_google_analytics_id !== null) {
    gtag('config', params.tracking_google_analytics_id, {
      ...getClientFields(),
    });
  }

  if (params.tracking_org_google_analytics_id !== null) {
    gtag('config', params.tracking_org_google_analytics_id, {
      ...getClientFields(),
    });
  }
}
