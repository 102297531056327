
import Vue from 'vue'
import customMapState from '@/helpers/mapHelper'
import { IRootState } from '@/models/IRootState'
import { UpsellConfig, UpsellThreshold } from '@/models/IDonationParams'
import { currenciesMap } from '@/constants/currencies'
import { mapGetters } from 'vuex'

export default Vue.extend({
  data() {
    return {
      used: false,
    }
  },

  computed: {
    ...customMapState({
      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,
      originAmount: (state: IRootState) => state.donation.originAmount,
      donationData: (state: IRootState) => state.donation.donationData.attributes,
      changeRate: (state: IRootState) => state.donation.changeRate || 1,
    }),
    ...mapGetters({
      someGatewaySupportRecurring: 'someGatewaySupportRecurring',
      someGatewaySupportInstallment: 'someGatewaySupportInstallment',
      showInstallmentOption: 'showInstallmentOption',
      showRecurringOption: 'showRecurringOption',
    }),

    donationCurrency(): string {
      return this.donationData.currency
    },

    amountInCampaignCurrency(): number {
      return Math.ceil(this.originAmount * this.changeRate)
    },

    totalAmountInCampaignCurrency(): number {
      const {
        recurring, recurring_period, installment, installment_period,
      } = this.donationData
      if (recurring) {
        return this.amountInCampaignCurrency * recurring_period
      }
      if (installment) {
        return this.amountInCampaignCurrency / installment_period
      }
      return this.amountInCampaignCurrency
    },

    totalAmountInSelectedCurrency(): number {
      const {
        recurring, recurring_period, installment,
      } = this.donationData
      if (recurring) {
        return this.originAmount * recurring_period
      }
      if (installment) {
        return this.originAmount
      }
      return this.originAmount
    },

    upsellConfig(): UpsellConfig {
      return this.config.upsell_config
    },

    upsellThresholds(): UpsellThreshold[] {
      if (this.upsellConfig.value) {
        return this.upsellConfig.upsell_thresholds || []
      }
      return []
    },

    upsellItems(): UpsellThreshold[] {
      if (this.upsellConfig.value && !this.used) {
        return this.upsellThresholds.filter(i => {
          if (
            i.amount_from <= this.totalAmountInCampaignCurrency
            && i.amount_to >= this.totalAmountInCampaignCurrency
          ) {
            switch (i.upsell_type) {
              case 'recurring':
                return this.someGatewaySupportRecurring && this.showRecurringOption

              case 'installments':
                return this.someGatewaySupportInstallment && this.showInstallmentOption

              default:
                return true
            }
          }

          return false
        })
      }
      return []
    },
  },

  methods: {
    getAmount(item: UpsellThreshold) {
      if (item.upsell_amount_percentage && !item.upsell_amount) {
        const percentageAmt = Math.ceil(
          this.totalAmountInSelectedCurrency * (item.upsell_amount_percentage / 100),
        )
        switch (item.upsell_type) {
          case 'recurring':
            return Math.round(
              (this.totalAmountInSelectedCurrency + percentageAmt) / item.upsell_duration,
            )

          case 'installments':
            return Math.round(
              (this.totalAmountInSelectedCurrency + percentageAmt) / item.upsell_duration,
            )

          default:
            return this.originAmount + percentageAmt
        }
      }
      return Math.ceil(item.upsell_amount / this.changeRate)
    },

    getUpsellAmountFmt(item: UpsellThreshold): string {
      const donationCurrencyObj = currenciesMap[this.donationCurrency]
      const amt = this.getAmount(item)

      switch (this.donationCurrency) {
        case 'eur':
          return `${amt}${donationCurrencyObj.sign}`

        default:
          return `${donationCurrencyObj.sign}${amt}`
      }
    },

    getUpsellAmountDiffFmt(item: UpsellThreshold): string {
      if (!item.upsell_amount) {
        return '';
      }

      const donationCurrencyObj = currenciesMap[this.donationCurrency]
      const amt = Math.ceil(item.upsell_amount / this.changeRate)
      const amtDiff = amt - this.originAmount

      switch (this.donationCurrency) {
        case 'eur':
          return `${amtDiff}${donationCurrencyObj.sign}`

        default:
          return `${donationCurrencyObj.sign}${amtDiff}`
      }
    },

    onClickUpsell(item: UpsellThreshold) {
      const amt = this.getAmount(item)

      const track = (amount: number) => {
        this.$store.commit(
          'setCustomDataField',
          {
            key: 'upsell_type',
            type: 'string',
            value: item.upsell_type,
          },
        );
        this.$store.commit(
          'setCustomDataField',
          {
            key: 'upsell_amount',
            type: 'string',
            value: String(amount),
          },
        );
        if (item.upsell_duration) {
          this.$store.commit(
            'setCustomDataField',
            {
              key: 'upsell_duration',
              type: 'string',
              value: String(item.upsell_duration),
            },
          );
        }
      }

      switch (item.upsell_type) {
        case 'recurring':
          this.$store.commit('setRecurringPeriod', item.upsell_duration);
          this.$store.commit('setOriginAmount', amt);
          track(amt)
          break;

        case 'installments':
          this.$store.commit('setInstallmentStatus', Boolean(item.upsell_duration));
          this.$store.commit('setInstallmentPeriod', item.upsell_duration);
          this.$store.commit('setOriginAmount', amt * item.upsell_duration);
          track(amt * item.upsell_duration)
          break

        default:
          this.$store.commit('setOriginAmount', amt);
          track(amt)
          break;
      }

      this.used = true;
    },
  },
})
