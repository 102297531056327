import { render, staticRenderFns } from "./MeshulamBankTransfer.vue?vue&type=template&id=434efd8c&scoped=true&"
import script from "./MeshulamBankTransfer.vue?vue&type=script&lang=ts&"
export * from "./MeshulamBankTransfer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "434efd8c",
  null
  
)

export default component.exports