
import { useExternalScript } from '@/helpers/useExternalScript';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';
import Vue from 'vue';

export default Vue.extend({
  computed: {
    ...customMapState({
      test: (s: IRootState) => s.donation.donationData.attributes.test || false,
    }),
  },

  mounted() {
    const meshulamWalletScript = useExternalScript('https://cdn.meshulam.co.il/sdk/gs.min.js')
    meshulamWalletScript?.load()

    meshulamWalletScript?.onLoad(() => {
      const config = {
        environment: this.test ? 'DEV' : 'PRODUCTION',
        version: 1,
        events: {
          onFailure: (e: any) => {
            this.$store.commit('setError', {
              title: 'Meshulam Wallet Error',
              text: e.message,
            });
          },
          onError: (e: any) => {
            this.$store.commit('setError', {
              title: 'Meshulam Wallet Error',
              text: e.message,
            });
          },
          onSuccess: (e: any) => {
            window.postMessage({
              type: 'gateway-response-success',
            }, '*')
          },
          onTimeout: (e: any) => { console.log('timeout', e) },
          onWalletChange: (e: any) => { console.log('wallet changed', e) },
        },
      };

      window.growPayment.init(config);
    })
  },
})
